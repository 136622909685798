import React from "react";
import ReactDOM from "react-dom";

import BannerText from "./components/BannerText";

import "./styles/normalize.css";
import "./styles/styles.scss";

import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Titillium Web:400,700", "sans-serif"]
  }
});

function App() {
  return (
    <div className="App">
      <div className="banner">
        <BannerText />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
