import React from "react";
import GitHub from "../../icons/Github";
import Twitter from "../../icons/Twitter";
import OpensNew from "../../icons/OpensNew";
import posed from "react-pose";

const childOptions = {
  open: { y: 0, opacity: 1 },
  closed: { y: 20, opacity: 0 }
};

const TextContainer = posed.div({
  open: {
    opacity: 1,
    delayChildren: 200,
    staggerChildren: 75
  },
  closed: { opacity: 0, delay: 300 }
});

const Headline = posed.h1({ ...childOptions });
const Paragraph = posed.p({ ...childOptions });
const Adiv = posed.div({ ...childOptions });

class BannerText extends React.Component {
  constructor() {
    super();

    this.state = {
      showText: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ showText: true }), 300);
  }

  render() {
    return (
      <TextContainer
        pose={this.state.showText ? "open" : "closed"}
        className="text-container"
      >
        <div className="text-container-inner">
          <Headline>Þórður Hafliðason</Headline>
          <Paragraph>
            web-dev. css. all things js. chiefly react. a11y.
          </Paragraph>
          <div className="icon-container">
            <Adiv className="icon-group">
              <a href="https://github.com/thordurhhh" rel="noopener noreferrer" target="_blank" aria-label="Thordur H GitHub account">
                <GitHub className="icon" />
                <OpensNew className="opens-new" />
              </a>
            </Adiv>
            <Adiv className="icon-group">
              <a href="https://www.twitter.com/tolleinn" rel="noopener noreferrer" target="_blank" aria-label="Thordur H Twitter account">
                <Twitter onClick={() => console.log("test")} className="icon" />
                <OpensNew className="opens-new" />
              </a>
            </Adiv>
          </div>
        </div>
      </TextContainer>
    );
  }
}

export default BannerText;
