import React from "react";

const Twitter = props => (
  <svg viewBox="0 0 486.392 486.392" {...props}>
    <path
      d="M243.196 0C108.891 0 0 108.891 0 243.196s108.891 243.196 243.196 243.196 243.196-108.891 243.196-243.196C486.392 108.861 377.501 0 243.196 0zm120.99 188.598l.182 7.752c0 79.16-60.221 170.359-170.359 170.359-33.804 0-65.268-9.91-91.776-26.904 4.682.547 9.454.851 14.288.851 28.059 0 53.868-9.576 74.357-25.627-26.204-.486-48.305-17.814-55.935-41.586 3.678.699 7.387 1.034 11.278 1.034 5.472 0 10.761-.699 15.777-2.067-27.39-5.533-48.031-29.7-48.031-58.701v-.76c8.086 4.499 17.297 7.174 27.116 7.509-16.051-10.731-26.63-29.062-26.63-49.825 0-10.974 2.949-21.249 8.086-30.095 29.518 36.236 73.658 60.069 123.422 62.562-1.034-4.378-1.55-8.968-1.55-13.649 0-33.044 26.812-59.857 59.887-59.857 17.206 0 32.771 7.265 43.714 18.908a121.25 121.25 0 0 0 38.03-14.531c-4.469 13.984-13.953 25.718-26.326 33.135a120.032 120.032 0 0 0 34.382-9.424c-8.025 11.977-18.209 22.526-29.912 30.916z"
      fill="#010002"
    />
  </svg>
);

export default Twitter;
